import React, { useState } from 'react';
import axios from 'axios';

const StockSearch = ({ setStockData }) => {
  const [stockSymbol, setStockSymbol] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setStockSymbol(event.target.value.toUpperCase());
  };

  const fetchData = async () => {
    if (!stockSymbol) {
      setError('Please enter a stock symbol');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const apiKey = process.env.REACT_APP_API_KEY; // Ensure your API key is in .env
      const response = await axios.get(`https://www.alphavantage.co/query?function=OVERVIEW&symbol=${stockSymbol}&apikey=${apiKey}`);

      if (response.data.Note) {
        setError('API request limit reached. Please try again later.');
      } else if (!response.data.Symbol) {
        setError('Invalid stock symbol. Please try a different one.');
      } else {
        setStockData(response.data); // Update the state in App.js
      }
    } catch (err) {
      setError('Error fetching data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  return (
    <div>
      <p>Please enter a stock symbol:</p>
      <form onSubmit={handleSubmit}>
        <input type="text" value={stockSymbol} onChange={handleInputChange} />
        <button type="submit">Search</button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default StockSearch;
