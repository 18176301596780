// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#topImage {
    width: 100%;
    height: auto;
    display: block; /* Removes any default spacing around the image */
    max-height: 200px; /* Adjust this value as needed */
    object-fit: cover; /* This property will ensure the aspect ratio is maintained without distorting the image */
}
`, "",{"version":3,"sources":["webpack://./src/TopBar/TopImage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,cAAc,EAAE,iDAAiD;IACjE,iBAAiB,EAAE,gCAAgC;IACnD,iBAAiB,EAAE,0FAA0F;AACjH","sourcesContent":["#topImage {\n    width: 100%;\n    height: auto;\n    display: block; /* Removes any default spacing around the image */\n    max-height: 200px; /* Adjust this value as needed */\n    object-fit: cover; /* This property will ensure the aspect ratio is maintained without distorting the image */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
