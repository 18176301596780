import React from 'react';
import './TopImage.css';

export const TopImage = () => {
    return (
        <div>
            <img
                id="topImage"
                style={{
                    width: '100%',
                    height: 'auto',
                }}
                src="./helsinki.jpg"
                alt="benjamins"
            />
        </div>
    );
};
