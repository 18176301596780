import React, { useState, useEffect } from 'react';
import './StocksTable.css';

const StocksTable = () => {
    const [stocks, setStocks] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        fetch('/database/nasdaq50DataFull.json')
            .then(response => response.json())
            .then(data => setStocks(data));
    }, []);

    const sortedStocks = React.useMemo(() => {
        let sortableItems = [...stocks];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [stocks, sortConfig]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <table className="StocksTable">
            <thead>
                <tr>
                    <th onClick={() => requestSort('Name')}>Company</th>
                    <th>Ticker</th>
                    {[
                        'MarketCapitalization',
                        'DividendYield',
                        'EPS',
                        'RevenuePerShareTTM',
                        'TrailingPE',
                        'ForwardPE',
                        'PriceToBookRatio',
                        'ReturnOnEquityTTM',
                        'ProfitMargin',
                        'OperatingMarginTTM',
                    ].map(column => (
                        <th key={column} onClick={() => requestSort(column)}>
                            {column}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortedStocks.map((stock, index) => (
                    <tr key={index}>
                        <td>{stock.Name}</td>
                        <td>
                            <a
                                href={`https://finance.yahoo.com/quote/${stock.Symbol}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {stock.Symbol}
                            </a>
                        </td>
                        <td>{stock.MarketCapitalization}</td>
                        <td>{stock.DividendYield}</td>
                        <td>{stock.EPS}</td>
                        <td>{stock.RevenuePerShareTTM}</td>
                        <td>{stock.TrailingPE}</td>
                        <td>{stock.ForwardPE}</td>
                        <td>{stock.PriceToBookRatio}</td>
                        <td>{stock.ReturnOnEquityTTM}</td>
                        <td>{stock.ProfitMargin}</td>
                        <td>{stock.OperatingMarginTTM}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default StocksTable;
