import React from 'react';
import { TopImage } from './TopImage';
import './TopBar.css';

export const TopBar = () => {
    return (
        <container>
            <div className="TopBar">
                <h1>THE BENJAMINS</h1>
                <h2>Helping you value invest and hold</h2>
                <TopImage />
                <a href="/">Home</a>
                <a href="/StockSearchPage">Stock Search</a>
                {/* <a href="/SignIn">Sign in</a> */}
                {/* <a href="/MarketStatus">Markets status</a> */}
                <a href="/TestPage">Nasdaq Top 50</a>
            </div>
        </container>
    );
};
