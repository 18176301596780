import MarketGenerator from './MarketGenerator';
import { WorldClocks } from './WorldClocks';

export const MarketStatus = () => {
  return (
    <div className="market-status">
      <h1>Global Markets</h1>
      {/* <WorldClocks /> */}
      <MarketGenerator />
    </div>
  );
};
