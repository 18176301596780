import React, { useState, useEffect } from 'react';

// Assuming the JSON data is stored in public/database/stockStatusListing.json
const dataPath = '/database/listingStatus.json';

function SimpleStockTableComponent() {
  const [data, setData] = useState([]);

  // Load the data from the JSON file
  useEffect(() => {
    fetch(dataPath)
      .then(response => response.json())
      .then(setData);
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Name</th>
          <th>Exchange</th>
          <th>Asset Type</th>
          <th>IPO Date</th>
          <th>Delisting Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>{item.name}</td>
            <td>{item.exchange}</td>
            <td>{item.assetType}</td>
            <td>{item.ipoDate}</td>
            <td>{item.delistingDate === 'null' ? '' : item.delistingDate}</td>
            <td>{item.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SimpleStockTableComponent;
