import '../StocksTable/StocksTable.css';

export const StockData = ({ data }) => {
    if (!data) return null;

    return (
        <div className="">
            <h2>
                {data.Name} ({data.Symbol})
            </h2>
            <div className="StocksTable">
                <div>Exchange: {data.Exchange}</div>
                <div>Market Cap: {data.MarketCapitalization}</div>
                <div>PE Ratio: {data.PERatio}</div>
                <div>Dividend Yield: {data.DividendYield}</div>
                <div>EPS: {data.EPS}</div>
                <div>52 Week High: {data['52WeekHigh']}</div>
                <div>52 Week Low: {data['52WeekLow']}</div>
                <div>50 Day Moving Average: {data['50DayMovingAverage']}</div>
                <div>200 Day Moving Average: {data['200DayMovingAverage']}</div>
                <div>Shares Outstanding: {data.SharesOutstanding}</div>
                <div>Shares Float: {data.SharesFloat}</div>
                <div>Shares Short: {data.SharesShort}</div>
                <div>Shares Short Prior Month: {data.SharesShortPriorMonth}</div>
                <div>Short Ratio: {data.ShortRatio}</div>
                <div>Book Value: {data.BookValue}</div>
                <div>Price to Book Ratio: {data.PriceToBookRatio}</div>
                <div>EBITDA: {data.EBITDA}</div>
                <div>PEGRatio: {data.PEGRatio}</div>
                <div>Price to Sales Ratio: {data.PriceToSalesRatioTTM}</div>
                <div>Price to Cash Flow Ratio: {data.PriceToCashflowRatioTTM}</div>
                <div>Beta: {data.Beta}</div>
                <div>Profit Margin: {data.ProfitMargin}</div>
                <div>Operating Margin: {data.OperatingMarginTTM}</div>
                <div>Return on Assets: {data.ReturnOnAssetsTTM}</div>
                <div>Return on Equity: {data.ReturnOnEquityTTM}</div>
                <div>Revenue Per Share: {data.RevenuePerShareTTM}</div>
                <div>Market Cap to Revenue: {data.MarketCapToRevenueTTM}</div>
                <div>Enterprise Value: {data.EnterpriseValue}</div>
                <div>Enterprise Value to Revenue: {data.EnterpriseValueToRevenueTTM}</div>
                <div>Price to Free Cash Flow: {data.PriceToFreeCashFlowsTTM}</div>
                <div>Quick Ratio: {data.QuickRatio}</div>
                <div>Current Ratio: {data.CurrentRatio}</div>
                <div>Debt to Equity: {data.DebtToEquity}</div>
                <div>Debt to Assets: {data.DebtToAssets}</div>
                <div>Net Debt to EBITDA: {data.NetDebtToEBITDA}</div>
                <div>Long Term Debt to Equity: {data.LongTermDebtToEquity}</div>
                <div>Short Term Debt to Equity: {data.ShortTermDebtToEquity}</div>
                <div>Free Cash Flow: {data.FreeCashFlowTTM}</div>
                <div>Operating Cash Flow: {data.OperatingCashFlowTTM}</div>
                <div>Return on Tangible Assets: {data.ReturnOnTangibleAssetsTTM}</div>
                <div>Total Debt to Total Assets: {data.TotalDebtToTotalAssets}</div>
                <div>Interest Coverage: {data.InterestCoverage}</div>
                <div>PE Ratio: {data.PEGRatio}</div>
                <div>Forward PE Ratio: {data.ForwardPERatio}</div>
                <div>PEG Ratio: {data.PEGRatio}</div>
                <div>PEGY Ratio: {data.PEGYRatio}</div>
                <div>Price to Sales Ratio: {data.PriceToSalesRatioTTM}</div>
                <div>Price to Book Ratio: {data.PriceToBookRatio}</div>
                <div>EV to Revenue: {data.EVToRevenue}</div>
                <div>EV to EBITDA: {data.EVToEBITDA}</div>
                <div>Beta: {data.Beta}</div>
            </div>
        </div>
    );
};
