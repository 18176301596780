import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TopBar } from './TopBar/TopBar';
import StockSearch from './StockSearchPage/StockSearch';
import { StockData } from './StockSearchPage/StockData';
import { StockSearchPage } from './StockSearchPage/StockSearchPage';
import { MarketStatus } from './MarketStatus/MarketStatus';
import { HomePage } from './HomePage/HomePage';
import { KelloReact } from './MarketStatus/KelloReact';
import { ModelPortfolio, StockList } from './ModelPortfolio/ModelPortfolio';
import { TestPage } from './TestPage/TestPage';
import StockDetail from './StockDetail/StockDetail';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <TopBar />
                <Routes>
                    <Route path="/StockSearchPage" element={<StockSearchPage />} />
                    <Route path="/SignIn" element={<KelloReact />} />
                    <Route path="/ModelPortfolio" element={<ModelPortfolio />} />
                    <Route path="/MarketStatus" element={<MarketStatus />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/TestPage" element={<TestPage />} />
                    <Route path="/stock-detail/:ticker" element={<StockDetail />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
