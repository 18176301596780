import './HomePage.css';

export const HomePage = () => {
    return (
        <div>
            <p className="HomePage">
                The Benjamins is an investing information database that helps you find value stocks
                that you can buy and hold for long periods of time safely and soundly. We believe in
                value investing. Value investing was made famous by investment legends Warren
                Buffett and Benjamin Graham. Warren Buffet has made an annualized return of 19.8%
                from 1965 to the present, compared with 9.9% for the S&P 500 index. Benjamin Graham
                before him made an annualized return of 20% from 1936 to 1956. Value investing works
                and it is the only successful LONG TERM investment strategy for the individual
                investor. We recommend that the primary saving strategy for any retail investor is
                to own low cost index ETF's (Equity Traded Fund) that follow a diversified index
                such as the S&P 500. However we acknowlegede that investors want to own individual
                stocks. If chosen correctly and held with discipline, value stocks can indeed
                provide excessive returns. We are here to help you find those stocks for you to buy
                and hold.
            </p>
            <img src="/pulic/warren.jpg" alt="Warren Buffett" />
        </div>
    );
};
