import React, { useState } from 'react';
import axios from 'axios';

function UpdateStocksButton() {
  const [status, setStatus] = useState('');

  const updateStocks = async () => {
    try {
      const response = await axios.get('http://localhost:3001/update-stocks');
      setStatus(response.data);
    } catch (error) {
      console.error('Error updating stocks:', error);
      setStatus('Error updating stocks');
    }
  };

  return (
    <div>
      <button onClick={updateStocks}>Update Stocks</button>
      <p>{status}</p>
    </div>
  );
}

export default UpdateStocksButton;
