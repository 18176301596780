// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StocksTable {
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    background-color: #f9f9f9;
    border: 5px solid #ddd;
    border-radius: 8px;
    align-items: left;
    width: 92%;
    position: absolute;
    top: 300;
    left: 0;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/StocksTable/StocksTable.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,QAAQ;AACZ","sourcesContent":[".StocksTable {\n    margin-left: auto;\n    margin-right: auto;\n    border-collapse: collapse;\n    background-color: #f9f9f9;\n    border: 5px solid #ddd;\n    border-radius: 8px;\n    align-items: left;\n    width: 92%;\n    position: absolute;\n    top: 300;\n    left: 0;\n    right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
