import { StockData } from './StockData';
import StockSearch from './StockSearch';
import React, { useState } from 'react';

export const StockSearchPage = () => {
  const [stockData, setStockData] = useState(null);

  return (
    <div>
      <StockSearch setStockData={setStockData} />
      <StockData data={stockData} />
    </div>
  );
};
