// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBar {
    z-index: 1000;
    overflow: hidden;
    background-color: black;
    color: #f2f2f2;
    padding: 10px 10px;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.TopBar a:hover {
    background-color: #ddd;
    color: black;
}

/* Style the topnav links */
.TopBar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/TopBar/TopBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;AACZ;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA,2BAA2B;AAC3B;IACI,WAAW;IACX,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".TopBar {\n    z-index: 1000;\n    overflow: hidden;\n    background-color: black;\n    color: #f2f2f2;\n    padding: 10px 10px;\n    text-align: center;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n}\n\n.TopBar a:hover {\n    background-color: #ddd;\n    color: black;\n}\n\n/* Style the topnav links */\n.TopBar a {\n    float: left;\n    display: block;\n    color: #f2f2f2;\n    text-align: center;\n    padding: 14px 16px;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
