import React from 'react';
import { Clock } from './Clock';
import './worldclocks.css'; // Create this CSS file for styling

export const WorldClocks = () => {
  return (
    <div className="world-clocks">
      <Clock timezoneOffset={-5} ClassName="city" label="New York" />
      <Clock timezoneOffset={0} ClassName="city" label="London" />
      <Clock timezoneOffset={2} ClassName="city" label="Helsinki" />
      <Clock timezoneOffset={9} ClassName="city" label="Tokyo" />
    </div>
  );
};
