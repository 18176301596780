// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("clock-face.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.clock {
  width: 300px;
  height: 300px;
  position: relative;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: cover;
  transform: scale(0.5); /* Scale down to 50% */
}

.hour-hand,
.minute-hand,
.second-hand {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 100%;
}

.hour-hand {
  width: 6px;
  height: 60px;
  background-color: black;
  z-index: 3;
}

.minute-hand {
  width: 4px;
  height: 80px;
  background-color: black;
  z-index: 2;
}

.second-hand {
  width: 2px;
  height: 90px;
  background-color: red;
  z-index: 1;
}

.center-circle {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/MarketStatus/clock.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,oEAAkD;EAClD,sBAAsB;EACtB,qBAAqB,EAAE,sBAAsB;AAC/C;;AAEA;;;EAGE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":["body {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #f0f0f0;\n}\n\n.clock {\n  width: 300px;\n  height: 300px;\n  position: relative;\n  background: url('clock-face.png') no-repeat center;\n  background-size: cover;\n  transform: scale(0.5); /* Scale down to 50% */\n}\n\n.hour-hand,\n.minute-hand,\n.second-hand {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform-origin: 50% 100%;\n}\n\n.hour-hand {\n  width: 6px;\n  height: 60px;\n  background-color: black;\n  z-index: 3;\n}\n\n.minute-hand {\n  width: 4px;\n  height: 80px;\n  background-color: black;\n  z-index: 2;\n}\n\n.second-hand {\n  width: 2px;\n  height: 90px;\n  background-color: red;\n  z-index: 1;\n}\n\n.center-circle {\n  width: 10px;\n  height: 10px;\n  background-color: black;\n  border-radius: 50%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
