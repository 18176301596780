import React, { useState, useEffect } from 'react';

const dataPath = '/database/listingStatus.json';

function StockTableWithFiltersComponent() {
  const [data, setData] = useState([]);
  const [exchange, setExchange] = useState('');
  const [assetType, setAssetType] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetch(dataPath)
      .then(response => response.json())
      .then(data => {
        setData(data);
        // Do not automatically set filtered data here
      });
  }, []);

  // Handler for the submit button
  const handleSubmit = () => {
    const filtered = data.filter(
      item =>
        (exchange ? item.exchange === exchange : true) &&
        (assetType ? item.assetType === assetType : true)
    );
    setFilteredData(filtered);
  };

  // Extract unique options for filters
  const exchanges = Array.from(new Set(data.map(item => item.exchange)));
  const assetTypes = Array.from(new Set(data.map(item => item.assetType)));

  return (
    <div>
      <div>
        <label>Exchange: </label>
        <select value={exchange} onChange={e => setExchange(e.target.value)}>
          <option value="">All Exchanges</option>
          <option value="NASDAQ">NASDAQ</option>
          <option value="NYSE">NYSE</option>
          <option value="NYSEARCA">NYSEARCA</option>
          <option value="NYSEMKT">NYSEMKT</option>
          <option value="BATS">BATS</option>
          <option value="OTC">OTC</option>
          <option value="OTCBB">OTCBB</option>
          <option value="OPRA">OPRA</option>
          <option value="CBOE">CBOE</option>
          <option value="IEXG">IEXG</option>
          <option value="EDGA">EDGA</option>
          <option value="EDGX">EDGX</option>
          <option value="ARCX">ARCX</option>
          <option value="PHLX">PHLX</option>
          <option value="BATY">BATY</option>
          <option value="BATS">BATS</option>
          <option value="BATS-EDGA">BATS-EDGA</option>
          <option value="BATS-EDGX">BATS-EDGX</option>
          <option value="BATS-Y">BATS-Y</option>
          <option value="BATS-Z">BATS-Z</option>
          <option value="BYX">BYX</option>
          <option value="BZX">BZX</option>
          <option value="IEX">IEX</option>
          <option value="ISE">ISE</option>
          <option value="MIAX">MIAX</option>

          {exchanges.map((ex, index) => (
            <option key={index} value={ex}>
              {ex}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Asset Type: </label>
        <select value={assetType} onChange={e => setAssetType(e.target.value)}>
          <option value="">All Asset Types</option>
          <option value="Stock">Stock</option>
          <option value="ETF">ETF</option>
          <option value="ADR">ADR</option>
          <option value="REIT">REIT</option>
          <option value="Closed-End-Fund">Closed-End-Fund</option>
          <option value="Limited-Partnership">Limited-Partnership</option>
          <option value="ETN">ETN</option>
          <option value="Warrant">Warrant</option>
          <option value="Index">Index</option>
          <option value="Currency">Currency</option>
          <option value="Commodity">Commodity</option>
          <option value="Futures">Futures</option>
          <option value="Option">Option</option>
          <option value="Bond">Bond</option>
          <option value="MF">MF</option>
          <option value="Money-Market-Fund">Money-Market-Fund</option>
          <option value="Municipal-Bond">Municipal-Bond</option>
          {assetTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleSubmit}>Submit</button>

      <table>
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Name</th>
            <th>Exchange</th>
            <th>Asset Type</th>
            <th>IPO Date</th>
            <th>Delisting Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>{item.name}</td>
              <td>{item.exchange}</td>
              <td>{item.assetType}</td>
              <td>{item.ipoDate}</td>
              <td>{item.delistingDate === 'null' ? '' : item.delistingDate}</td>
              <td>{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StockTableWithFiltersComponent;
