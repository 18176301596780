import React, { useState, useEffect } from 'react';
import axios from 'axios';

function MarketGenerator() {
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://www.alphavantage.co/query?function=MARKET_STATUS&apikey=demo'
        );
        setMarkets(response.data.markets || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="tables-container">
      {markets.map((market, index) => (
        <div key={index} className="market-table">
          <h2>
            Market: {market.region} - {market.market_type}
          </h2>
          <table>
            <tbody>
              {Object.entries(market).map(([key, value], idx) => (
                <tr key={idx}>
                  <td>{key}</td>
                  <td>{value.toString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default MarketGenerator;
