import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const formatToPercentage = (value, maximumFractionDigits = 0) => {
  return `${(value * 100).toFixed(maximumFractionDigits)}%`;
};

const formatMarketCap = value => {
  // Convert to millions
  const millions = value / 1000000;

  // Format the number with space as thousand separator
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(millions) + ' M';
};

const StockDetail = () => {
  const { ticker } = useParams();
  const [stockDetails, setStockDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStockDetails = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Ensure your API key is in .env

        const response = await axios.get(
          `https://www.alphavantage.co/query?function=OVERVIEW&symbol=${ticker}&apikey=${apiKey}`
        );
        setStockDetails(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchStockDetails();
  }, [ticker]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2 id="stock-details"> Stock Details for {ticker}</h2>
      <div className="button.container">
        <button id="stock-details-button" onClick={() => window.history.back()}>
          Add Stock to Portfolio
        </button>
      </div>
      {stockDetails ? (
        <div>
          <table className="stockpage-table">
            <thead>
              <tr>
                <th colSpan="2">Stock Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{stockDetails.Name}</td>
              </tr>
              <tr>
                <td>Symbol</td>
                <td>{stockDetails.Symbol}</td>
              </tr>
              <tr>
                <td>Market Cap</td>
                <td>{formatMarketCap(stockDetails.MarketCapitalization)}</td>
              </tr>
              <tr>
                <td>Exchange</td>
                <td>{stockDetails.Exchange}</td>
              </tr>
              {/* You can add more fields as needed */}
            </tbody>
          </table>
          <table className="stockpage-table">
            <thead>
              <tr>
                <th colSpan="2">Financials</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Price</td>
                <td>{stockDetails.Name}</td>
              </tr>
              <tr>
                <td>DividendPerShare</td>
                <td>{stockDetails.DividendPerShare}</td>
              </tr>
              <tr>
                <td>DividendYield</td>
                <td>{formatToPercentage(stockDetails.DividendYield)}</td>
              </tr>
              {/* You can add more fields as needed */}
            </tbody>
          </table>
          <table className="stockpage-table">
            <thead>
              <tr>
                <th colSpan="2">Valuation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Trailing PE</td>
                <td>{stockDetails.TrailingPE}</td>
              </tr>
              <tr>
                <td>Forward PE</td>
                <td>{stockDetails.ForwardPE}</td>
              </tr>
              <tr>
                <td>Price to Book Ratio</td>
                <td>{stockDetails.PriceToBookRatio}</td>
              </tr>
              <tr>
                <td>Book Value</td>
                <td>{stockDetails.BookValue}</td>
              </tr>
              {/* You can add more fields as needed */}
            </tbody>
          </table>
          <table className="stockpage-table">
            <thead>
              <tr>
                <th colSpan="2">Operating performance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Return on Equity TTM</td>
                <td>{(stockDetails.ReturnOnEquityTTM * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Return on Assets TTM</td>
                <td>{(stockDetails.ReturnOnAssetsTTM * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Profit Margin TTM</td>
                <td>{(stockDetails.ProfitMargin * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Operating Margin TTM</td>
                <td>{(stockDetails.OperatingMarginTTM * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Quarterly Earnings Growth YOY</td>
                <td>{(stockDetails.QuarterlyEarningsGrowthYOY * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Quarterly Revenue Growth YOY</td>
                <td>{(stockDetails.QuarterlyRevenueGrowthYOY * 100).toFixed(2)} %</td>
              </tr>
              {/* You can add more fields as needed */}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No details available for this ticker.</p>
      )}
    </div>
  );
};

export default StockDetail;
