import React from 'react';
import { LogInStockList } from '../LogInStockList';
import FetchDataButton from '../ApiCallCentral/FetchDataButton';
import StockTable from '../StockTable';
import StockTableWithFiltersComponent from '../StockTableWithFiltersComponent';
import StocksTable from '../StocksTable/StocksTable';

export const TestPage = () => {
    return (
        <div>
            <h2>Top 50 Nasdaq Metrics</h2>
            {/* <LogInStockList /> */}
            {/* <FetchDataButton /> */}
            {/* <StockTable /> */}
            {/* <StockTableWithFiltersComponent /> */}
            <StocksTable />
        </div>
    );
};
