import React, { useState, useEffect } from 'react';
import './clock.css';

export const Clock = ({ timezoneOffset = 0, label }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      const currentTime = new Date();
      currentTime.setHours(currentTime.getUTCHours() + timezoneOffset);
      setTime(currentTime);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timezoneOffset]);

  const hourDegrees = (time.getHours() % 12) * 30 + time.getMinutes() * 0.5;
  const minuteDegrees = time.getMinutes() * 6 + time.getSeconds() * 0.1;
  const secondDegrees = time.getSeconds() * 6;

  return (
    <div className="clock-container">
      <div className="clock">
        <div
          className="hour-hand"
          style={{ transform: `translate(-50%, -100%) rotate(${hourDegrees}deg)` }}
        ></div>
        <div
          className="minute-hand"
          style={{ transform: `translate(-50%, -100%) rotate(${minuteDegrees}deg)` }}
        ></div>
        <div
          className="second-hand"
          style={{ transform: `translate(-50%, -100%) rotate(${secondDegrees}deg)` }}
        ></div>
        <div className="center-circle"></div>
      </div>
      <div className="clock-label">{label}</div>
    </div>
  );
};
