// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.world-clocks {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.clock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clock-label {
  margin-top: 0px;
  font-size: 1.8em;
  background-color: #ddd;
  border: 0px solid transparent; /* Set a transparent border */
  border-radius: 5%; /* Make the box round */
  padding: 4px; /* Add some padding inside the box */
  /* display: inline-block; This helps to maintain the round shape */
}
`, "",{"version":3,"sources":["webpack://./src/MarketStatus/worldclocks.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,6BAA6B,EAAE,6BAA6B;EAC5D,iBAAiB,EAAE,uBAAuB;EAC1C,YAAY,EAAE,oCAAoC;EAClD,kEAAkE;AACpE","sourcesContent":[".world-clocks {\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n  padding: 20px;\n}\n\n.clock-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.clock-label {\n  margin-top: 0px;\n  font-size: 1.8em;\n  background-color: #ddd;\n  border: 0px solid transparent; /* Set a transparent border */\n  border-radius: 5%; /* Make the box round */\n  padding: 4px; /* Add some padding inside the box */\n  /* display: inline-block; This helps to maintain the round shape */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
