import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UpdateStocksButton from './UpdateStockButton';

export const ModelPortfolio = () => {
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    // Fetch the stock data from the JSON file
    fetch('./stocks.json')
      .then(response => response.json())
      .then(data => setStocks(data))
      .catch(error => console.error('Error fetching stock data:', error));
  }, []);

  return (
    <div>
      <h2>Model Portfolio</h2>
      <UpdateStocksButton />
      <table className="stock-table">
        <thead>
          <tr>
            <th>Ticker</th>
            <th>Name</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {stocks.map(stock => (
            <tr key={stock.ticker}>
              <td>
                <Link to={`/stock-detail/${stock.ticker}`}>{stock.ticker}</Link>
              </td>
              <td>{stock.name}</td>
              <td>${stock.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
